import { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { retry } from '../common/utils/CommonFunctions'
import { useAuthContext } from '../hooks/useAuthContext'

const Sidebar = lazy(() => retry(() => import('../layout/Sidebar')))
const Login = lazy(() => retry(() => import('../pages/Login')))
const FourOhFourHome = lazy(() =>
  retry(() => import('../pages/FourOhFourHome')),
)
// const Home = lazy(() => retry(() => import('../pages/Home')))
const Support = lazy(() => retry(() => import('../pages/Support')))
const PrivacyPolicy = lazy(() => retry(() => import('../pages/PrivacyPolicy')))
const DeleteAccount = lazy(() => retry(() => import('../pages/DeleteAccount')))

const Routes = () => {
  const { token } = useAuthContext()

  const routes = useRoutes([
    {
      path: '/',
      // element: <Home />,
      element: <Navigate replace to="/admin/login" />,
    },
    {
      path: '/admin/login',
      element: !token ? <Login /> : <Navigate replace to="/admin/dashboard" />,
    },
    {
      path: '/admin',
      element: <Navigate replace to="/admin/login" />,
    },
    {
      path: '/admin/*',
      element: token ? <Sidebar /> : <Navigate replace to="/admin/login" />,
    },
    {
      path: '/*',
      element: <Navigate replace to="/404" />,
    },
    {
      path: '/404',
      element: <FourOhFourHome />,
    },
    {
      path: '/support',
      element: <Support />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/delete-account',
      element: <DeleteAccount />,
    },
  ])

  return routes
}

export default Routes
